.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header-main {
  background: #00693e;
  padding-top: 12px;
  height: 3.6rem;
}

.app-name {
  font-size: 2rem;
  font-weight: bolder;
  font-style: italic;
  color: white;
  padding-left: 8px;
  letter-spacing: 1.2px;
  font-family: 'Times New Roman', Times, serif;
  float: left;
}

.user-name {
  font-size: 1.3rem;
  color: white;
  padding-right: 8px;
  padding-top: 11px;
  font-style: italic;
  float: right;
  margin: auto;
  letter-spacing: 1.2px;
  font-family: 'Times New Roman', Times, serif;
}

.status-tab a {
  background-image: linear-gradient(to top, white, #f06d06);
  border-top: 1.5px solid #753708 !important;
}

.organ-tab a {
  background-image: linear-gradient(to top, white, rgb(13, 196, 13));
  border-top: 1.5px solid green !important;
}

li.selected-tab a,
li.p-highlight a {
  background-image: linear-gradient(to top, white, #007ad9) !important;
  border-top: 1.5px solid #007ad9 !important;
  border-bottom: none !important;
}

.error-msg {
  font-size: 10px;
  color: red;
}
